const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://dowjonestoday.online',
    gaTrackingId: 'G-P1G0BQV7WL',
    trailingSlash: false,
  },
  header: {
    logo: 'dowjonestoday_logo.png',
    logoLink: '',
    title:
      "Welcome To DowJonesToday.Online",
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: `<li>
		    <a href="https://twitter.com/" target="_blank" rel="noopener">
		      <div class="twitterBtn">
		        <img src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/twitter-brands-block.svg' alt={'Twitter'}/>
		      </div>
		    </a>
		  </li>
			`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/basics', // add trailing slash if enabled above
      '/history',
    ],
    collapsedNav: [
      '/codeblock', // add trailing slash if enabled above
    ],
    links: [ 
      { text: ' Contact Us', link: 'basics' },
      { text: ' Privacy Policy', link: 'basics' },
      { text: ' Terms of Use', link: 'basics' },
      { text: ' About Us', link: 'basics' }
    ],
    frontLine: true,
    ignoreIndex: true,
    title:
      "<a href='/'> Dow Jones Today </a><div class='greenCircle'></div>",
  },
  siteMetadata: {
    title: 'Everything about The Dow Jones Industrial Average Index Today',
    description: 'Dow Jones Today - charts, real-time quotes, market data, news and much more... ',
    ogImage: null,
    docsLocation: '',
    favicon: 'pwa-512.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Dow Jones',
      short_name: 'Dow',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
